@mixin note-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;

  ::first-letter {
    text-transform: capitalize;
  }
}

.newsletter-ua {
  position: fixed;
  z-index: 9;
  height: 100vh;
  top: 50%;
  width: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;

  &-page {
    height: 124%;
    overflow: scroll;
    background-color: #f4f7f9;

    .btn {
      &:disabled {
        opacity: 0.5 !important;
        cursor: not-allowed !important;

        &:hover {
          visibility: visible;
          content: attr(title);
        }
      }
    }

    &__sub-header {
      display: flex;
      z-index: 0;
      margin-right: 1.5rem;
      position: relative;
      height: 96px;
      border-left: 1px solid $french-pass;
      border-right: 1px solid $french-pass;
      border-bottom: 1px solid $french-pass;
      border-radius: 0px 0px 10px 10px;
      background-color: $alice-pass;
      margin-top: 0;

      &__info {
        width: 51%;
        display: flex;
        align-items: center;
        font-family: "Roboto";
        font-style: normal;
        color: $pickled-bluewood;
        margin-left: 79px;

        &__title {
          display: flex;
          align-items: center;
          height: 60px;

          img {
            width: 36px;
          }

          .title {
            padding-left: 20px;
            border-left: 1px solid #fff;
            margin-left: 20px;

            h3 {
              color: $pickled-bluewood;
              font-family: $main-body-font;
              font-size: 24px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              margin-bottom: 5px;
            }

            p {
              color: $fiord;
              font-family: $main-body-font;
              font-size: 12px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
              text-transform: uppercase;
              margin-bottom: 0;
            }
          }
        }
      }

      &__btn {
        display: inline-flex;
        align-items: center;
        margin-right: 59px;
        position: absolute;
        right: 0;
        top: 25px;
        background: $dodger-blue;
        box-shadow: 0px 4px 10px 0px rgba(41, 57, 77, 0.10);
        color: #FFF;
        text-align: center;
        font-family: Roboto;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.96px;
        padding: 12px 24px;
        border-radius: 30px;
        gap: 14px;

        &:hover {
          box-shadow: none;
          background-color: $curious-blue;
          cursor: pointer;
        }
      }

      &.is-ua-approval {
        margin-left: 1.6rem;

        h3 {
          margin-bottom: 0;
        }
      }
    }

    &__list {
      padding-bottom: 3rem;
      overflow-y: auto;
      height: 100vh;
      margin-left: 27px;

      .filter-wrapper:after {
        content: none !important;
      }

      .h-separator {
        border-bottom: 1px solid #E1E4E8;
        max-width: 91.5%;
        margin: 0 auto 13px;
      }

      .nb-campaigns {
        color: $pickled-bluewood;
        font-family: "Roboto";
        font-size: 12px;
        font-weight: 400;

        span {
          font-weight: 500;
        }
      }

      .gradient {
        height: 1.5rem;
        width: 21rem;
        background: linear-gradient(to right, #F9F9F9, #EEE, #E5E5E5, #DEDEDE);
        background-size: 300% 100%;
        border-radius: 5px;
      }
    }

    &__send {
      overflow-y: auto;
      height: 100vh;
      padding-bottom: 12rem;
      position: relative;

      &-steps {
        position: absolute;
        width: 305px;
        left: 80px;
        top: 25px;
        background: $athens-gray;
        border-radius: 5px;
        border: 1px solid var(--Gris-3, #e1e4e8);
        height: fit-content;

        h5 {
          font-weight: 600;
          font-size: 18px;
          line-height: 21px;
          color: #29394d;
          margin: 1.85rem 0 0.5rem 1.5rem;
          text-transform: uppercase;
        }

        h6 {
          font-weight: 400;
          font-size: 12px;
          line-height: 140%;
          color: #6d7f92;
          margin: 0 1.5rem 3rem 1.5rem;
        }

        .step-item {
          position: relative;
          display: inline-block;
          border-top: 1px solid #d8dde2;
          margin-left: 25px;
          align-items: center;
          cursor: pointer;

          &:first-child {
            margin-top: 5rem;
          }

          &:last-child {
            border-bottom: 1px solid #d8dde2;
            margin-bottom: 1.85rem;
          }

          div {
            display: flex;
            width: 254px;
          }

          &__id {
            height: 28px;
            width: 28px;
            border-radius: 50%;
            line-height: 1.8rem;
            color: #ffffff;
            font-weight: 500;
            font-size: 16px;
            text-align: center;
            margin: 0.5rem 1.5rem 0.5rem 5px;
          }

          &__title {
            font-weight: 500;
            text-transform: capitalize;
            display: flex;
            align-items: center;
            text-transform: uppercase;
          }

          .container {
            &__warning {
              background: #fffaf3;
              color: #6d7f92;
              margin: 2px 0 12px 0;
              padding: 12px;
              border-radius: 3px;
              word-spacing: 2px;
              @include note-text;

              .alert_circle {
                margin-right: 8px;
              }
            }

            &__info {
              background: #c7e8fe;
              margin: 2px 0 12px 0;
              padding: 12px;
              border-radius: 3px;
              color: #29394d;
              @include note-text;
              line-break: anywhere;
              text-align: justify;


              ul {
                list-style-type: unset;
                margin-bottom: auto;

                li::marker {
                  color: #18a0fb;
                }
              }
            }
          }

          &.active {
            .step-item__id {
              background: #18a0fb;
            }

            .step-item__title {
              color: #18a0fb;
            }
          }

          &.disabled {
            .step-item__id {
              background: #b2bcc6;
            }

            .step-item__title {
              color: #b2bcc6;
            }
          }
        }
      }

      &-tabs {
        min-height: calc(100vh - 160px);
        position: relative;
        left: 25.3rem;
        top: 25px;
        border-radius: 5px;
        background: #ffffff;
        overflow-y: auto;
        height: 40rem;
      }
    }

    .subscription-item {
      background: rgba(255, 255, 255, 0.95);
      box-shadow: 4px 4px 20px rgba(194, 194, 194, 0.2);
      border-radius: 20px;
      padding: 3.5rem 7.5rem .5rem;
      width: 45%;
      margin: 4.5rem auto;
      text-align: center;

      img {
        height: 8rem;
        margin-bottom: 1rem;
      }

      .subscription-block {
        .subscription-header {
          font-size: 30px;
          font-weight: 300;
          color: #29394D;
          margin: 0 0 2rem;
          text-align: center;
        }

        .subscription-content {
          font-family: "Helvetica", "Arial", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
          color: #193A61;
        }

        .subscription-actions {
          display: flex;
          justify-content: space-between;
          margin-top: 2rem;
          padding: 2rem 0 0;
          border-top: 1px solid #F1F2F4;

          .btn-unsubscribe {
            background: #F84054;

            &:hover {
              background: #CC3544;
            }
          }
        }

        .unsubscribe-from-tt {
          display: flex;
          justify-content: center;
          margin-top: 2rem;
          cursor: pointer;

          span {
            font-size: 9px;
            color: gray;
            text-decoration: underline;
          }
        }
      }
    }

  }

  .menu {
    background: $pickled-bluewood;
    height: 44px;
    margin: 2px 0;
    display: flex;
    align-items: center;
    padding-left: 5rem;

    li {
      .menu-ua__link {
        font-family: $main-body-font;
        text-transform: uppercase;
        height: 100%;
        color: $french-pass;
        display: flex;
        justify-content: space-around;
        align-items: center;

        span {
          margin-left: 5px;
        }

        &:hover,
        &.active {
          background: var(--Dark-2, $fiord);
        }
      }
    }
  }

  .top-bar {
    position: relative;
    display: flex;
    background: #29394d;
    text-transform: uppercase;
    height: 62px;
    color: white;

    .title-container {
      span {
        margin-left: -6.3rem;
        font-weight: 500;
        font-size: 16px;

        &.tag-plan {
          color: #29394d;
          font-size: 0.75rem;
          font-style: normal;
          line-height: normal;

          border-radius: 0.3125rem;
          background: #fff;
          padding: 0.125rem 0.375rem;
        }
      }
    }

    svg {
      fill: white;
    }

    &_close {
      margin: 0 0.5rem 0 1rem;
    }

    .btn-container {
      display: flex;

      button {
        height: 38px;
        margin-left: 10px;

        &.save {
          color: white;
          background: transparent;
          border: 1px solid #ffffff;
        }

        &.success {
          min-width: 120px;
        }
      }
    }
  }

  .preview-sidebar {
    width: 1035px;
    left: unset !important;
    display: flex;
    justify-content: center;
    top: -2px !important;

    &__close {
      position: absolute;
      top: 2rem;
      font-size: medium;
      right: 2rem;
      cursor: pointer;
      text-transform: uppercase;
      color: #6d7f92;

      &-icon {
        margin-top: 4px;
        position: absolute;
      }

      &-title {
        margin-right: 1rem;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
      }
    }

    &__content {
      padding: 1rem;
      margin-bottom: 4rem;

      .campaign-preview__device {
        padding-bottom: 5px;
      }
    }

    &__loader {
      position: fixed;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background: white;
      z-index: 1;
    }
  }
}

#send-tabs__tab {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .tab {
    padding: 2px 3rem;
    min-height: auto;

    &-header {
      font-weight: 600;
      font-size: 18px;
      line-height: 21px;
      color: #29394d;
      text-align: center;
      padding: 1rem 0 0.5rem;
      text-transform: uppercase;
      margin-top: 15px;
    }

    .version-select > .ttp-select__control {
      border: 0;
      outline: 0;
      border-radius: 30px;
      background-color: #6D7F92;
      color: #FFF !important;
      padding-left: 8px;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      font-family: Roboto;
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: 0.24px;

      .ttp-select__single-value {
        color: white !important;

        .ttp-select__control {
          border-color: #6D7F92;
          box-shadow: 0 0 0 1px #6D7F92;

        }
      }

    }


    .tabs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 98.5%;
      border: none;
      cursor: pointer;
      margin-top: 1rem;
      position: relative;
      // margin-left: 0.8rem;

      &.tab_centered_content {
        justify-content: center;
      }

      .tab_badge {
        width: auto;
        min-width: 10rem;
        margin: 0 3px;
        padding: 0.3rem 0.5rem;
        border-bottom: 3px solid;
        border-color: #b2bcc6;
        cursor: pointer;

        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        line-height: 16px;
        color: #6d7f92;

        img {
          margin: -2px 6px 0 0;
        }

        &_active {
          border-color: #18a0fb;
          color: #18a0fb;
        }
      }

      .save_selection {
        display: flex;
        align-items: center;
        margin-left: auto;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 5px;
          border: 1px solid #E1E4E8;
          background: #FFF;
          color: #29394D;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          padding: 0.4rem 0.8rem;
          cursor: pointer;

          img {
            margin-right: 0.4rem;
          }
        }
      }

      .category_section {
        display: flex;
        align-items: flex-start;
        gap: 10px;
        flex-wrap: wrap;

        .group_recipients_section {
          color: #6D7F92;
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 6px;

          .group_recipients_count {
            width: 160px;
            height: 20px;
            flex-shrink: 0;
            display: block;
            background: #F1F2F4;
            text-align: center;
            border-radius: 5px;
            color: #6D7F92;
            font-family: Roboto;
            font-size: 12px;
            font-weight: 500;

            &_active {
              background-color: #C7E8FE;
            }
          }

          &:hover {
            .tab_badge {
              color: #18a0fb;
              border-color: #18a0fb;
            }
          }
        }
      }

      .separator_container {
        display: flex;
        width: 57rem;
        justify-content: center;
        align-items: center;

        .v-separator {
          width: 1px;
          height: 52px;
          flex-shrink: 0;
          align-self: stretch;
          background: #E1E4E8;
        }
      }

      .total_section {
        width: 10rem;
        text-align: center;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #6d7f92;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 12px;
        flex-shrink: 0;

        .total_number {
          font-size: 12px;
          font-weight: 500;

          .tooltip-icon {
            display: inline-block;
            position: relative;
            cursor: pointer;
            margin-left: 0.2rem;
          }

          .tooltip-icon:hover .tooltip-text {
            visibility: visible;
            opacity: 1;
          }

          .tooltip-text {
            visibility: hidden;
            width: 325px;
            background-color: #333;
            color: #fff;
            text-align: center;
            border-radius: 4px;
            padding: 8px;
            position: absolute;
            z-index: 1;
            bottom: 125%;
            left: -3.5rem;
            margin-left: -100px;
            opacity: 0;
            transition: opacity 0.3s;
          }

          .tooltip-text::after {
            content: '';
            position: absolute;
            top: 100%;
            left: 50%;
            margin-left: -5px;
            border-width: 5px;
            border-style: solid;
            border-color: #333 transparent transparent transparent;
          }

          .tooltip-icon svg {
            vertical-align: middle;
          }
        }
      }
    }

    .separator {
      margin: 2rem 0 0.3rem 1rem;
      border-bottom: 1px solid #d8dde2;
      width: 96.5%;
    }

    &-input {
      margin: 0;
      padding-left: 1rem;
      min-height: 2.4375rem;
    }

    &-label {
      font-weight: 500;
      font-size: 16px;
      // line-height: 16px;
      color: #29394d;
      padding: 1.5rem 0 13px 1px;
      display: block;
    }

    .attachments-list {
      margin-left: 0;
    }

    .attachments-dropzone {
      width: 820px;
      height: 110px;
      background: #f8f9fa;
      border: 1px dashed #b6bfc8;
      border-radius: 5px;
      margin-top: 0;

      p {
        font-weight: 400;
      }

      .icon-cloud-upload {
        font-size: 2rem;
        padding-bottom: 0.7rem;
      }
    }

    .from-email-select {
      position: relative;

      .validation-icon {
        position: absolute;
        right: 3.3rem;
        top: 0.7rem;
        z-index: 1;
      }
    }

    .react-tagsinput-input {
      padding-left: 1rem;
    }

    .user-suggestion {
      padding: 0;
      border: 1px solid #18a0fb;
      border-radius: 4px;
    }

    .user-suggestion a.react-tagsinput-remove {
      font-size: 1.2rem;
      color: #2495e1;
      margin-left: 280px;
      top: -20px;
      position: relative;
    }

    .tags-input-container.empty-box {
      border: none;
    }

    .ttp-select {
      flex: auto;
    }

    .inline-editor {
      box-sizing: border-box;
      cursor: text;
      border-bottom: 1px solid #b6bfc8;
    }

    .send-summary {
      margin: 1rem;
      padding-right: 2rem;

      li {
        display: flex;
        border-top: 1px solid #f1f2f4;
        align-items: center;
        padding: 7px 0;

        div:first-child {
          color: #6d7f92;
          text-align: right;
          width: 19%;
        }

        div:nth-child(2) {
          display: flex;
          color: #29394d;
          padding-left: 10px;
        }
      }

      svg {
        margin-left: 10px;
      }

      li:last-child {
        border-bottom: 1px solid #f1f2f4;
      }
    }

    .send-buttons {
      display: flex;
      padding-top: 1.5rem;

      .check-box-container {
        .check-box {
          width: 18px;
          height: 18px;
          border-radius: 18px;
        }

        img {
          width: 9px;
        }
      }

      &__item {
        width: 388px;
        height: 58px;
        background: #ffffff;
        border: 1px solid #b2bcc6;
        box-shadow: 0px 4px 10px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        display: flex;
        padding-left: 5px;
        margin: 0.6rem;
        cursor: pointer;

        &.large_block {
          width: fit-content;
          padding: 1rem 1rem 1rem 0.5rem;
          margin-bottom: 0;
          margin-top: 0;
        }

        span {
          font-weight: 500;
          color: #29394d;
        }

        &.checked {
          background: #f3faff;
          border: 1px solid #18a0fb;
        }
      }

      input[type="text"] {
        display: inline-block;
        font-size: 1rem;
        width: 50%;
        margin: 1rem 1rem 0.2rem 2rem;
        border: none;
      }

      .input-moment {
        margin-left: 2rem;

        .tab {
          padding: unset;

          .toolbar {
            margin-bottom: 0;

            button {
              font-size: 14px;
            }
          }
        }
      }
    }

    .auto-send-block {
      display: flex;
      padding-top: 1rem;

      .check-box-container {
        .check-box {
          width: 18px;
          height: 18px;
          border-radius: 1px;
        }

        img {
          width: 9px;
        }
      }

      .item {
        display: flex;
        margin: 0 0.6rem;
        cursor: pointer;
        width: fit-content;
        padding: 1rem 1rem 0rem 0.5rem;

        span {
          font-weight: 500;
          color: #29394d;
        }

        &.checked {
          background: #f3faff;
          border: 1px solid #18a0fb;
        }
      }
    }

    .counter {
      display: flex;

      span:nth-child(2) {
        margin-left: auto;
      }
    }

    .email-tags__input {
      background: #f8f9fa;
      border: 1px solid #b6bfc8;
      border-radius: 5px;

      &.lite {
        min-height: 30px;
        height: auto;
      }

      .react-tagsinput,
      .react-tagsinput-input {
        background: #f8f9fa;
      }
    }

    .list-filter__bar {
      margin: 1rem auto 1rem auto;
      align-items: center;

      .tab-label {
        padding-top: 1rem;
      }


    }

    .filter-wrapper {
      display: flex;
      justify-content: flex-end;
      border-radius: 3px;
      transition: all .3s;
      position: relative;

      &__tabs {
        display: flex;
        flex-grow: 1;
        height: 2rem;

        span {
          background: #F1F2F4;
          border: .5px solid #F1F2F4;
          box-sizing: border-box;
          border-radius: 30px;
          margin-right: .4rem;
          font-size: 12px;
          line-height: 14px;
          text-align: center;
          letter-spacing: 0.02em;
          text-transform: uppercase;
          color: #6D7F92;
          padding: 9px 16px;
          cursor: pointer;
          transition: background-color .3s;
          white-space: nowrap;

          &.active {
            background: #6D7F92;
            color: #FFFFFF;
            font-weight: 500;
            text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.4);
          }

          &:hover:not(.active) {
            background-color: rgba(109, 127, 146, 0.2);
          }
        }
      }

      &::after {
        content: "";
        position: absolute;
        bottom: -.6rem;
        height: 1px;
        width: 100%;
        background: #F1F2F4;
      }
    }

    .ttp-datatable div {
      margin-left: 5px;
      justify-content: unset;
      align-items: center;

      .recipient-name {
        display: flex;
        font-size: 12px;
        font-weight: 400;
      }

      .email-target {
        line-break: anywhere;
        display: flex;
        align-items: center;
        text-align: right;
        font-family: Roboto;
        font-size: 12px;
        font-weight: 400;

        &.bounced {
          color: #FC5D2B;
        }

        svg {
          margin-right: 0.4rem;
        }

        .email-text {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 150px;
          display: inline-block;
        }
      }

      .groups-name {
        height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        transition: 0.3s ease;
        width: 20px;

        &:hover {
          height: auto;
          white-space: normal;
        }
      }
    }

    .required {
      font-weight: 600;
      font-size: 15px;

      .alert {
        color: red;
      }
    }

    .team-list {
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      margin-bottom: 3rem;

      &-item {
        cursor: pointer;
        margin: .3rem;

        &__name {
          font-size: 1.2rem;
          color: #29394D;
        }

        .param-label {
          text-indent: 0;
        }

        .team-header {
          border-top: 1px solid #E1E4E8;
          border-bottom: 1px solid #E1E4E8;


          .icon {
            padding-right: 0.2rem;
            font-size: .6rem;
          }

          &--members {
            font-size: .7rem;
            padding: 0 .5rem;
            justify-content: flex-end;
            color: var(--dark-1, #29394D);
            text-align: right;
            font-family: Roboto;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 2.2;

            &-avatars {
              display: flex;
              flex-direction: row-reverse;
              justify-content: center;

              .small-avatar {
                background-size: cover;
                vertical-align: middle;
                position: relative;
                width: 40px;
                height: 40px;
                flex-shrink: 0;
                overflow: hidden;
                border-radius: 50%;
                border: 2px solid white;
                margin-left: -8px;
                cursor: default;
                background-color: gray;

                &:hover {
                  z-index: 1;
                }
              }

              .empty-avatar span {
                font-size: 1rem;
              }
            }

            .icon {
              font-size: 1.3rem;
            }
          }


          &.delete {
            background: #FFF7F8;

            &:hover {
              border: 1px solid #FE3745;
            }
          }

          .icon-pencil {
            display: none;
            font-size: .8rem;
          }

          &:hover .icon-pencil {
            display: inline-block;
          }
        }

        .label_delete {
          font-weight: 500;
          font-size: 12px;
          line-height: 14px;
          color: #FE3745;
          text-transform: uppercase;
          cursor: pointer;
          margin: 10px 0 10px 10px;

          svg {
            margin-right: 13px;
          }
        }

        .team-setting {
          height: 0;
          overflow: hidden;
          padding: 0rem 1rem;
          background-color: #fbfbfb;
          //border-top: 2px solid #ededed;
          transition: .3s all ease-in-out;
          grid-gap: 25px;

          h6 {
            text-align: center;
          }

          .setting-param {
            display: inline-flex;

            .ttp-select {
              width: 20%;

              &.empty-box {
                border: unset;

                .ttp-select__control {
                  background: white;
                  border-color: rgb(179, 179, 179);
                }
              }
            }
          }

          .flip-card {
            background-color: transparent;
            width: 300px;
            height: 250px;
            perspective: 1000px;

            &-inner {
              position: relative;
              width: 100%;
              height: 100%;
              transition: transform 0.6s;
              transform-style: preserve-3d;
              box-shadow: 0 4px 18px -4px rgba(0, 0, 0, 0.2);
            }

            &:hover .flip-card-inner {
              transform: rotateY(180deg);
            }

            &-front {
              display: flex;
              justify-content: center;
              align-items: center;
              color: black;

              .approver {
                justify-content: flex-start;
                height: auto;
                margin: 1rem 0;
                text-align: center;
                align-content: center;
                align-items: center;
                flex-direction: column;
                display: -webkit-flex;
                display: -ms-flexbox;
                display: flex;
                -webkit-flex-direction: column;
                -ms-flex-direction: column;
                flex-direction: column;
                -webkit-align-items: center;
                -ms-flex-align: center;
                align-items: center;

                &-avatar {
                  position: relative;
                  overflow: hidden;
                  cursor: pointer;
                  overflow: inherit;
                  opacity: 0.9;
                  border-radius: 50%;
                  background-size: cover;
                  background-position: 50%;
                  width: 6rem;
                  height: 6rem;
                  margin-bottom: 1rem;
                  border: 2px solid #fff;
                  box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);

                  .approver-icon {
                    position: absolute;
                    top: 0;
                    right: 4rem;
                    font-size: 2rem;
                    background-color: #fff;
                    height: 2.5rem;
                    width: 2.5rem;
                    border-radius: 50%;
                    color: #CCC;
                    cursor: pointer;
                    z-index: 1;

                    .active {
                      transition: nth($transitions, 2);
                      color: $main-blue;
                    }

                    .icon-check {
                      color: $persian-green;
                    }

                    .icon-clock {
                      color: $tango;
                    }

                    .icon-ban {
                      color: $coral-red;
                    }
                  }
                }

                .empty-avatar {
                  span {
                    font-size: 2.5rem;
                  }
                }

                .approver-info {
                  &-name {
                    font-size: 1rem;
                    margin: 0;
                    color: $gray-dark;
                  }

                  &-email {
                    color: $main-blue;
                    color: $dodger-blue;
                    font-size: .8rem;
                    margin: .2rem 0 .3rem;
                  }
                }
              }
            }

            &-back {
              background-color: #2980b9;
              color: #000;
              transform: rotateY(180deg);
              padding: .7rem;

              .ttp-button {
                background: #fff;
                text-align: center;
                display: inline-block;
                position: absolute;
                left: 0;
                right: 0;
                bottom: 0;
              }

              h3 {
                text-align: center;
                font-size: 1rem;
                margin: 0;
                color: $gray-dark;
              }
            }

            &-front,
            &-back {
              position: absolute;
              width: 100%;
              height: 100%;
              backface-visibility: hidden;
            }

            .pending {
              background: #FFFAF3;
            }

            .reject {
              background: #FFD5D5;
            }

            .approve {
              background: #F1FEF9;
            }
          }

        }

        .team-avatar {
          display: inline-block;
          color: #fff;
          text-align: center;
          margin: 10px 1px 10px 1px;
          // border: 2px solid #fff;
          //box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1), 0 0 0 1px rgba(44, 43, 63, 0.13);
          width: 44px;
          height: 44px;
          font-size: 1.8rem;
          line-height: 2.8rem;
          //overflow: hidden;
          text-transform: uppercase;
          border-radius: 50%;
          font-weight: bold;
        }

        p {
          color: #505050;
          margin: 0;
        }

        &__action span {
          text-transform: uppercase;
          padding: .5rem .8rem 0 0;
          font-size: .7rem;
          font-weight: normal;

          &:hover {
            color: #505050;
            letter-spacing: .03rem;
          }
        }
      }

      &-item--open {
        .team-setting {
          padding: 1.5rem 1rem;
          height: auto;
          overflow: auto;
          background-color: #fff;
        }
      }
    }
  }

  .tooltip-info {
    cursor: pointer;
    margin-left: 0.5rem;
  }

  .label-from {
    display: flex;
    flex-direction: column;

    &__name {
      color: #29394d;
    }

    &__email {
      color: #2495e1;
    }
  }

  .ttp-select__option--is-selected .label-from__email {
    color: #fff;
  }

  .email-field svg {
    right: auto;
    left: 0;
  }

  input:disabled,
  input:read-only {
    background: #f3faff;
  }

  .approver-team {
    display: flex;

    &__avatar {
      span {
        width: 2.5rem;
        height: 2.5rem;
        font-size: 1rem;
        line-height: 2.5rem;
        margin-right: 0.5rem;
        border: 2px solid #fff;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
        0 0 0 1px rgba(44, 43, 63, 0.13);
      }

      .team-avatar {
        display: inline-block;
        color: #fff;
        text-align: center;
        margin-right: 1rem;
        border: 2px solid #fff;
        box-shadow: 0 3px 12px 1px rgba(44, 43, 63, 0.1),
        0 0 0 1px rgba(44, 43, 63, 0.13);
        width: 3rem;
        height: 3rem;
        font-size: 1.4rem;
        line-height: 2.9rem;
        overflow: hidden;
        text-transform: uppercase;
        border-radius: 50%;
        font-weight: bold;
      }
    }

    &__detail {
      display: flex;
      flex-direction: column;

      span {
        font-size: 0.7rem;

        &:first-child {
          font-size: 0.9rem;
        }
      }
    }
  }

  .see-more {
    cursor: pointer;
    font-weight: 300;
    font-size: 12px;
    transition: all 0.3s ease-in-out;
    color: #18a0fb;
    float: right;

    .icon {
      margin-left: 0.3rem;
      font-size: 0.5rem;
    }
  }

  .notification {
    height: 47px;
    background: #edfaf5;
    border-radius: 5px;
    margin: auto auto 1rem auto;
    width: 85%;
    box-shadow: none;

    .message {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #29394d;

      &::first-letter {
        text-transform: capitalize;
      }

      .content {
        display: flex;
      }

      span {
        margin-left: 10px;
        margin-top: 1px;
      }
    }
  }

  .warning {
    height: auto;
    min-height: 50px;
    background: #eb57571a;
    border-radius: 5px;
    margin: 1rem auto;
    width: 97%;
    box-shadow: none;
    padding: 0.5rem 1rem;

    .message {
      .content {
        display: flex;
        align-items: center;

        .message-content {
          display: grid;

          span {
            margin-left: 10px;
            margin-top: 1px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #29394d;

            &::first-letter {
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }

  .info {
    height: auto;
    min-height: 50px;
    background: #c7e8fe;
    border-radius: 5px;
    margin: 0.5rem auto 1rem auto;
    width: 97%;
    box-shadow: none;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    .message {
      .message-content {

        // display: grid;
        span {
          margin-left: 10px;
          margin-top: 1px;
          font-family: "Roboto";
          font-weight: 400;
          color: #29394d;
          font-size: 0.75rem;
          font-style: normal;
          line-height: normal;

          &::first-letter {
            text-transform: capitalize;
          }
        }

        .desc_selection {
          color: #18a0fb;
          font-weight: 500;
          text-decoration-line: underline;
        }
      }
    }
  }

  .footer {
    position: sticky;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    border-top: 1px solid #d8dde2;
    padding: 4px 0 19px 0;
    box-sizing: border-box;
    margin: 0 30px;

    .footer-btns {
      display: flex;
      justify-content: flex-end;

      button {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        text-transform: uppercase;
        height: 44.9px;
        margin-top: 1.9%;
        cursor: pointer;

        &:disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .right-btn {
        width: 172px;
        background: #18a0fb;
        box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        color: #ffffff;

        span {
          padding: 0 0 0 22px;
        }
      }

      .return-btn {
        box-sizing: border-box;
        width: 138px;
        background: #ffffff;
        border: 1px solid #d8dde2;
        box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        color: #6d7f92;
        margin-right: auto;

        span {
          padding: 0 10px 0 0;
        }
      }

      .sendTest-btn,
      .send-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #06d9b1;
        box-shadow: 0px 2px 10px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        width: 24%;
        color: #ffffff;
        margin-right: 3vh;
      }

      .send-btn {
        width: 27%;
        margin-right: 0 !important;

        span {
          padding: 0 0 0 10px;

          svg {
            overflow: inherit !important;
          }
        }
      }

      .check-block {
        display: flex;
        align-items: center;
        margin-top: 0.7rem;
        margin-right: 1rem !important;
      }
    }
  }
}

$ep-toolbar-height: rem-calc(40px);
$ep-padding-top: $ep-toolbar-height + 1rem;
$ep-bottom-bar-height: rem-calc(70px);

#stats-page {
  .viewer-actions {
    padding-right: 0;

    .secondary-button {
      margin-right: 96px;
    }
  }

  .stats-page {
    position: relative;
    background: #fafbfb;
    padding: 0 96px 30px;
    margin-bottom: 4rem;

    &__block {
      background: #fff;
      border-radius: 5px;
      background: #fff;
      border-radius: 5px;
      padding: 1.5rem;
      margin: 20px 0 0;

      h4 {
        font-size: 24px;
        letter-spacing: 0.02em;
        color: #29394d;
      }
    }

    .summary {
      background: #fff;
      display: inline;

      li:last-of-type .flex-container {
        cursor: pointer;
        text-transform: capitalize;

        i {
          font-size: inherit;
        }
      }
    }

    .w-40 {
      width: 40%;
    }

    .w-58 {
      width: 58%;
    }
  }

  .filter-select {
    text-transform: uppercase;
    width: 300px;
  }

  .link-stats {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 1rem;
      padding: 0.6rem 0;
      cursor: pointer;

      &:hover {
        a {
          color: $main-blue;

          & + .count {
            background-color: $main-blue;
            color: #fff;
          }
        }
      }
    }
  }

  .scheduled-info {
    background: #f3faff;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;

    span {
      color: #29394d;
      font-size: 25px;
      text-align: center;
    }

    .scheduled-date {
      z-index: 1;
      position: relative;
      transition: all 0.3s ease-in-out !important;
      text-align: center;
      padding-top: 1rem;

      & > div {
        display: inline-block;
        padding: 10px 23px;
        position: relative;
        border-right: 3px solid #fff;

        & > div {
          font-size: 20px;
          text-transform: uppercase;
        }
      }

      & > div:last-child {
        border: unset;
      }
    }

    button {
      margin-bottom: 1rem;

      svg {
        margin-right: 10px;
      }
    }
  }

  .icon-refresh {
    font-size: 1.2rem;
    cursor: pointer;
  }

  #sidebar {
    width: 4.63rem;
    position: fixed;
    background-color: rgba(255, 255, 2555, 1);
    right: 0;
    box-shadow: 0px 1px 12px 0px rgba(0, 0, 0, 0.12);
    transition: nth($transitions, 2);
    color: #b9b9b9;
    top: 18rem;
    z-index: 3;

    li {
      position: relative;
      padding: 0.9rem 0;
      text-align: center;
      cursor: pointer;
      border-top: 1px solid #f2f2f2;

      &:first-child {
        border-top: 0;
      }

      .sidebar__icon {
        cursor: pointer;
        text-align: center;
        transition: nth($transitions, 3);
        font-size: 1.5rem;
      }

      &:hover {
        color: $main-blue;
        background-color: nth($main-blue-alpha, 1);

        .sidebar__item-hover {
          opacity: 1;
          z-index: 5;
          transform: translateX(-100%);
        }
      }

      &.activated {
        color: $main-blue;
        background-color: nth($main-blue-alpha, 1);
      }

      .sidebar__item-hover {
        position: absolute;
        background-color: $main-blue;
        color: #fff;
        padding: 1rem 0.2rem;
        transform: translateX(0%);
        left: 0;
        top: 0;
        bottom: 0;
        transition: nth($transitions, 2);
        z-index: 5;
        right: 0;
        width: 13rem;
        opacity: 0;
        font-weight: 600;
      }
    }
  }

  .icon-arrow-down,
  .icon-arrow-up {
    padding-left: 0.5rem;
    font-size: 1rem;
    cursor: pointer;
  }

  .ttp-datatable__row {

    .drop-down-option {
      display: inline-block;
      padding-right: 1rem;

      .drop-down-option__left {
        margin-right: -1rem;
        top: 60%;
      }
    }

    .send-status {
      .dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: inline-block;
        margin-right: 0.4rem;
      }

      padding: 0.2rem 0.5rem;
      text-transform: uppercase;
      border-radius: 3rem;
      font-size: 0.7rem;
      background: #ffffff;
      font-weight: normal;
      font-size: 14px;
      color: #6d7f92;
      text-transform: uppercase;
    }
  }

  .icon-check,
  .icon-info {
    margin-left: 0.5rem;
  }

  .icon-check {
    color: #3adb76;
  }

  .flag-icon {
    margin-left: 1.2em;
  }

  .sms-count {
    margin-left: 1.5em;
  }

  .ttp-datatable__header {
    background-color: #3182bd;
    color: #fff;
    opacity: 0.8;
  }

  .filter-container {
    display: flex;
    position: relative;
    margin-bottom: 0.7rem;

    .search {
      margin: 0 0 -0.3rem -1rem;
    }

    .refresh {
      margin: 0.4rem 2rem 0 0 !important;
    }
  }

  .list-item__count {
    color: #fff;
    margin-bottom: -2rem;
  }

  .close {
    top: 1rem !important;
  }

  .link-filters span.active {
    background: #6d7f92;
    color: #ffffff;
    font-weight: 500;
    text-shadow: 0px 4px 4px rgba(41, 57, 77, 0.4);
  }

  .link-filters span {
    background: #f1f2f4;
    border: 0 solid #f1f2f4;
    box-sizing: border-box;
    border-radius: 30px;
    margin-right: 0.4rem;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #6d7f92;
    padding: 9px 17px;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .link-filters-container {
    padding-right: 9px;
    margin-top: 6px;
  }
}

#resend-email-form {
  .check-box-container span {
    font-size: 1rem;
  }

  .warning {
    background: #fcfaf2;
    font-size: 14px;
  }
}

.recipient-item {
  cursor: pointer;
  background-color: #F8F9FA;

  &_selected {
    background-color: #F3FAFF !important;

    &:hover {
      background-color: #C7E8FE !important;
    }
  }

  &:hover {
    background-color: #F1F2F4;
  }

  .check-box-container {
    &:hover {
      .check-box {
        border: 3px solid #18A0FB;
      }
    }
  }

  .valid-email {
    display: flex;
    align-items: flex-start;
    gap: 10px;

    .valid-email-container {
      display: flex;
      align-items: flex-start !important;
      gap: 10px;

      .email-select {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;

        .react-select-container {
          width: 240px;
          margin-left: 0 !important;
          margin-top: 4px;

          .react-select {
            &__control {
              margin-left: 0 !important;
              min-height: 32px;
              height: 32px;
              border-radius: 5px;
              color: #29394D;
              font-family: "Roboto";
              font-size: 12px;
              font-weight: 400;
              box-shadow: none;

              &:hover {
                border: 1px solid #18A0FB;
              }

              &--is-focused {
                border: 1px solid #18A0FB;
              }
            }

            &__option {
              width: 240px;
              color: #29394D;
              background-color: #fff;
              border-radius: 4px;
              margin-left: 0 !important;

              .special-option {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid #B2BCC6;
                gap: 10px;
                padding-top: 14px;
              }

              &:hover {
                background-color: #F1F2F4;
              }
            }

            &__single-value {
              color: #29394D;
              font-family: "Roboto";
              font-size: 12px;
              font-weight: 400;
            }

            &__menu {
              width: 240px;
              font-size: 12px;
              font-weight: 400;
              color: #29394D;
              margin-left: 0 !important;
            }

            &__menu-list {
              overflow: hidden;
            }

            &__value-container {
              padding: 0;
              height: 32px;
            }

            &__input {
              display: flex;
              align-items: flex-end;
              margin: 0 !important;

              input[type="text"] {
                height: 20px;
                font-size: 12px !important;
                font-weight: 400;
                color: #29394D;
                box-shadow: none;
              }
            }
          }

          .normal-icon {
            transition: transform 0.5s ease;
          }

          .rotate-icon {
            transform: rotate(180deg);
            transition: transform 0.5s ease;
          }
        }
      }

      .email-input {
        display: flex;
        flex-direction: column;
        align-items: flex-start !important;
        margin: 0 0 0 10px;
      }

      .email-error-msg {
        font-size: 12px;
        font-weight: 400;
        color: red;
      }

      .input-box {
        width: 240px;
        height: 32px;
        border: 1px solid #B2BCC6;
        border-radius: 5px;
        margin: 0 !important;
        font-weight: 400 !important;
        font-size: 12px !important;
        color: #29394D;

        &:focus {
          border: 1px solid #18A0FB;
        }

        &::placeholder {
          color: #6D7F92;
          font-size: 12px;
          font-weight: 400;
        }
      }

      .fix-btn {
        margin: 0;
        padding: 0;
      }
    }
  }

  .delete-btn {
    border: 1px solid red;
    border-radius: 9px;
    margin-left: 1rem;
    padding: 0.39rem 0.65rem;
    cursor: pointer;
  }
}

.check_select {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 0.3125rem;
  background: #f1f2f4;
  padding: 0.2rem 1rem 0.2rem 0;
  margin-right: auto;
  height: 2rem;
  width: fit-content;
  margin-bottom: 20px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    background-color: #E1E4E8;

    .check-box {
      border: 3px solid #18A0FB;
    }
  }
}

.search-filter {
  display: flex;
}
