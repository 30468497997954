$tab-button-height: 54px;
.ttp-tab-selector {
  nav {
    text-align: center;
  }
  button {
    cursor: pointer;
    text-transform: uppercase;

  }
}
.ttp-tab-container {
  background: #F4F7F9;
  margin-top: 3.5rem;
  .ttp-color-tab-selector {
    position: relative;

    nav {
      position: absolute;
      top: -$tab-button-height/2;
      left: 0;
      right: 0;
      font-size: 15px;

      button {
        background: #FFFFFF;
        box-shadow: 0px 4px 20px rgba(41, 57, 77, 0.1);
        border-radius: 5px;
        margin: 0 10px;
        display: inline-block;
        position: relative;
        width: 206px;
        height: $tab-button-height;
        font-family: $main-body-font;
        font-style: normal;
        font-size: 16px;
        text-align: center;
        letter-spacing: 0.02em;
        color: #18A0FB;
        outline: none;
        transition: box-shadow .2s, transform .2s;

        &:hover {
          box-shadow: 2px 2px 11px rgba(41, 57, 77, 0.05);
          transform: scale(0.99);
        }
      }

      button.active {
        background: linear-gradient(180deg, #2495E1 0%, #18A0FB 100%);
        border: 1px solid #EEF6FF;
        color: #fff;
      }
    }
  }

  & > div {
    padding: $spacing-s 0 $spacing-m;
  }
}

.ttp-simple-tab-selector {
  border-bottom: 1px solid #F1F2F4;

  button {
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.04em;
    color: #6D7F92;
    padding: 15px 25px 10px;
  }

  button.active {
    color: #18A0FB;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    border-bottom: 3px solid #18A0FB;
  }
}

.title {
  display: block;

  h3 {
    font-weight: 500;
    font-size: 18px;
    text-transform: uppercase;
    color: #29394D;
    margin: 0 auto;
  }

  p {
    font-size: 12px;
    line-height: 14px;
    color: #6D7F92;
    margin-bottom: 1.5rem;
  }
}
